
/*-------------------
   MENU
----------------------*/

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    content: "";
    vertical-align: middle;
    background: no-repeat 50%;
    background-size: 100% 100%
}

.main-nav {
    margin-bottom: -45px;
    z-index: 999;
}

.navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.navbar-dark .navbar-nav .nav-link {
    color:$light;
    font-weight: 700;
    letter-spacing: .5px;
    text-transform: uppercase;
}
.rounded-radius{
    border-radius: 5px;
}
.navbar {
    position: relative;
    padding: 1rem 1rem;
    

    &:before {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        transition: opacity .15s linear;
        opacity: 0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        background-color:$light;

    }
}

.fixed-top {
    position: fixed;
    z-index: 1030;
    right: 0;
    left: 0;
}


.navbar-dark {
    color:$light;
}


.navbar-light {
    color: $black;
    &:before {
        opacity: 1;
    }
}


.navbar .nav-item-divider {
    display: none;

    .nav-link {
        opacity: .25;

        .nav-link > span {
            // border-left: 1px solid ;
        }
    }
}



.nav-item.dropdown {
        .dropdown-menu{
        -webkit-transition: all 300ms ease;
        -o-transition:all 300ms ease;
        transition: all 300ms ease;
        display: block;
        visibility: hidden;
        opacity: 0;
        top: 120%;
        -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
        box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
        border: 0px;
        padding: 0px;
        position: absolute;
    }
    &:hover .dropdown-menu{
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
}


.header-bar.fixed-header .main-navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(20,27,40,.90);
    border-color: transparent;

}


/*-------------------
  DROPDOWN MENU
----------------------*/


.dropdown-menu {
    opacity: 0
}

.dropdown-menu.show {
    animation: .2s forwards b;
}

@keyframes b {
    to {
        opacity: 1
    }
}

.dropdown-item {
    font-size: .6875rem;
    font-weight: 500;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: background-color .1s;
    text-transform: uppercase;

    &:hover {
        color:$light;
        text-decoration: none;
        background-color: $primary-color;

    }
}




